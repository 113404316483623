import {
	IconButton,
	ListSubheader,
	makeStyles,
	Menu,
	MenuItem,
	SvgIcon,
	Tooltip,
	Typography
} from '@material-ui/core';
import React, { useState } from 'react';
import { Filter as FilterIcon } from 'react-feather';
import { filtersService } from '../services';

const useStyles = makeStyles(theme => ({
	root: {},
	menuItemTitle: {
		outline: 'none'
	}
}));

function Filters({ sort, filters, showExternalFilters, params }) {
	const classes = useStyles();
	const [menuRef, setMenuRef] = useState(null);
	var activeParams = filtersService.getActiveParams(params?.sort);
	
	function handleClickFilters(filter) {
		let _filters = params?.filters || {};

		if (filtersService.isActive(filter, _filters)) {
			_filters = filtersService.remove(filter, _filters);
		} else {
			_filters = filtersService.add(filter, _filters);
		}

		filters.handleFilters(_filters);
	}

	function handleClickSort(value) {
		let _params = {
			sort: value
		}

		sort.handleSort(_params);
	}

	if (!sort && !filters) return null;

	return (
		<>
			{(sort?.options || filters?.options) && (
				<Tooltip title="Ordina">
					<IconButton
						className={classes.root}
						onClick={event => setMenuRef(event.currentTarget)}
						color={Boolean(activeParams) ? 'primary' : null}
					>
						<SvgIcon>
							<FilterIcon />
						</SvgIcon>
					</IconButton>
				</Tooltip>
			)}

			<Menu
				open={Boolean(menuRef)}
				anchorEl={menuRef}
				onClose={() => setMenuRef(null)}
			>
				{sort?.options && (
					<ListSubheader className={classes.menuItemTitle}>
						Ordina
					</ListSubheader>
				)}

				{sort?.options?.map(s => (
					<MenuItem key={s.value} onClick={() => handleClickSort(s.value)}>
						<Typography
							variant="body2"
							color={params?.sort === s.value ? 'primary' : 'textPrimary'}
							style={{
								fontWeight: params?.sort === s.value && '800'
							}}
						>
							{s.label}
						</Typography>
					</MenuItem>
				))}

			{showExternalFilters ? "" : (
				<>
				{filters?.options && <ListSubheader>Filtri</ListSubheader>}

				{filters?.options?.map(x => (
					<MenuItem key={x.value} onClick={() => handleClickFilters(x.value)}>
						<Typography
							variant="body2"
							color={
								filtersService.isActive(x.value, params?.filters)
									? 'primary'
									: 'textPrimary'
							}
							style={{
								fontWeight: filtersService.isActive(x.value, params?.filters) && '800'
							}}
						>
							{x.label}
						</Typography>
					</MenuItem>
				))}
				</>
			)}
			</Menu>
		</>
	);
}

export default Filters;
